#form-privato {
  display: none;
  &.selected {
    display: block;
  }
}

#form-azienda {
  display: none;
  &.selected {
    display: block;
  }
}

.form-aziende-wrapper {
  #form-azienda {
    display: block;
  }
}

.custom-label {
  p {
    font-family: 'Barlow', sans-serif;
    font-size: 1em;
    line-height: 100%;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0.2em;
  }
  &.privacy {
    display: flex;
    gap: 8px;
  }
}

.form-section {
  background-color: var(--background-primary);
  margin-top: -10px;
}

.selection {
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: 821px) {
    width: 60%;
  }
}

.selection-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin-bottom: 1em;

  #radio-privato,
  #radio-azienda {
    display: none;
  }

  .label-radio-privato,
  .label-radio-azienda {
    background-color: var(--white);
    border-radius: 25px;
    border: 2px solid var(--white);
    min-width: 150px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    padding: 0 16px;
    &.selected {
      border: 2px solid var(--button-primary);
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

.custom-input {
  width: 95%;
  padding: 12px;
  background: transparent;
  border: none;
  border-radius: 5px;
  background-color: var(--white);
  margin-top: 8px;
  box-shadow: 0px 0px 0px 2px var(--white);
  transition: all 0.3s ease-in-out;
  appearance: none;
  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: 0px 0px 0px 2px var(--button-primary);
  }
  //   &:hover {
  //     box-shadow: 0px 0px 0px 1px var(--button-primary);
  //   }

  &-radio {
    width: auto;
    margin-top: 20px;
    margin-bottom: 14px;
  }
}

.form-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
}

.form-action_note {
  font-size: small;
  font-style: italic;
}

.form-helper-text {
  font-size: 9px;
  margin: 0 1em;
  font-style: italic;
}

#form-privato {
  display: none;
}

#form-privato.selected {
  display: block;
}

#form-azienda {
  display: none;
}

#form-azienda.selected, .form-aziende-wrapper #form-azienda {
  display: block;
}

.custom-label p {
  margin-top: 0;
  margin-bottom: .2em;
  font-family: Barlow, sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 100%;
}

.custom-label.privacy {
  gap: 8px;
  display: flex;
}

.form-section {
  background-color: var(--background-primary);
  margin-top: -10px;
}

.selection {
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 821px) {
  .selection {
    width: 60%;
  }
}

.selection-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin-bottom: 1em;
  display: flex;
}

.selection-container #radio-privato, .selection-container #radio-azienda {
  display: none;
}

.selection-container .label-radio-privato, .selection-container .label-radio-azienda {
  background-color: var(--white);
  border: 2px solid var(--white);
  min-width: 150px;
  height: 50px;
  cursor: pointer;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 16px;
  font-weight: 600;
  transition: all .3s ease-in-out;
  display: flex;
}

.selection-container .label-radio-privato.selected, .selection-container .label-radio-azienda.selected {
  border: 2px solid var(--button-primary);
  box-shadow: 0 0 30px #0003;
}

.custom-input {
  width: 95%;
  background: none;
  background-color: var(--white);
  box-shadow: 0px 0px 0px 2px var(--white);
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 5px;
  margin-top: 8px;
  padding: 12px;
  transition: all .3s ease-in-out;
}

.custom-input:focus, .custom-input:focus-visible {
  box-shadow: 0px 0px 0px 2px var(--button-primary);
  outline: none;
}

.custom-input-radio {
  width: auto;
  margin-top: 20px;
  margin-bottom: 14px;
}

.form-action {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
  display: flex;
}

.form-action_note {
  font-size: small;
  font-style: italic;
}

.form-helper-text {
  margin: 0 1em;
  font-size: 9px;
  font-style: italic;
}

/*# sourceMappingURL=form.d5a1ddda.css.map */
